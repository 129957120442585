import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const UNJJPage = () => {
  return (
    <Layout>
      <SEO title="UNJJ Congress Ostend 2022" />


      <div className="flex align--center wrap--small justify---center">
        <StaticImage src="../images/unjj_120.gif" width={120} alt="" className="center" />

        <h1>
          UNJJ Congress <br />
          Ostend 2022
        </h1>
      </div>


      <article>
        <h2>Program</h2>
        <h3>Friday 07 October</h3>
        <ol className="list--group">
          <li>18:00<span>
            Departure - to <a href="https://goo.gl/maps/ebVEWAWhmtabwA9m9">sports hall</a></span></li>
          <li>19:00<span>
            Opening ceremony</span></li>
          <li>20:00<span>
            Team demonstrations</span></li>
        </ol>
      </article>

      <article>
        <h3>Saturday 08 October</h3>
        <ol className="list--group">
          <li>07:30<span>
            Breakfast</span></li>
          <li>08:45<span>
            Departure – to <a href="https://goo.gl/maps/ebVEWAWhmtabwA9m9" target="blank">sports hall</a></span></li>
          <li>09:45<span>
            Line-up – on the tatami</span></li>
          <li>10:00<span>
            Start competitions</span></li>
          <li>18:00<span>
            End competitions</span></li>
        </ol>
      </article>


      <article>
        <h3>Sunday 09 October</h3>
        <ol className="list--group">
          <li>07:30<span>
            Breakfast</span></li>
          <li>08:45<span>
            Departure – to <a href="https://goo.gl/maps/ebVEWAWhmtabwA9m9" target="blank">sports hall</a></span></li>
          <li>09:45<span>
            Line-up – on the tatami</span></li>
          <li>10:00<span>
            Start course</span></li>
          <li>15:00<span>
            Closing ceremony & end course</span></li>
          <li>19:00
            <span>
              <a href="https://g.page/thermaepalace?share" target="blank">Party-dinner</a> <em>(cash only)</em>
            </span>
          </li>
        </ol>
      </article>


      <article>
        <h3>Monday 10 October</h3>
        <ol className="list--group">
          <li>10:00<span>
            Departure</span></li>
        </ol>
      </article>


      <article>
        <h2>
          Addresses
        </h2>
        <div className="grid col--2 pb--lg">
          <div className="pb--sm">
            <strong>Hotel Royal Astrid</strong>
            <address>
              Wellingtonstraat 15<br />
              8400 Oostende
            </address>
            <a href="https://goo.gl/maps/NwVsMUa5U6wbQFUU8" target="blank">Directions</a>
          </div>

          <div className="pb--sm">
            <strong>Hotel Prado</strong>
            <address>
              Leopold II-laan 22<br />
              8400 Oostende
            </address>
            <a href="https://goo.gl/maps/PKftb9YRnpaEMo2s7" target="blank">Directions</a>
          </div>
        </div>

        <div className="grid col--2">
          <div className="pb--sm">
            <strong>Sports hall</strong>
            <address>
              Koninklijke Stallingen<br />
              Koninginnelaan 78<br />
              8400 Oostende
            </address>
            <a href="https://goo.gl/maps/ebVEWAWhmtabwA9m9" target="blank">Directions</a>
          </div>
          <div className="pb--sm">
            <strong>Gala dinner</strong>
            <address>
              Zaal Albert Thermae Palace<br />
              Koningin Astridlaan 7<br />
              8400 Oostende
            </address>
            <a href="https://g.page/thermaepalace?share" target="blank">Directions</a>
          </div>
        </div>

      </article>


      <article>

        <h2>Download</h2>
        <ul>
          <li><a href="/docs/UNJJ Program - Prado.pdf">Program (Hotel Prado)</a></li>
          <li><a href="/docs/UNJJ Program - Royal Astrid.pdf">Program (Hotel Royal Astrid)</a></li>
        </ul>
      </article>
    </Layout>
  )
}

export default UNJJPage
